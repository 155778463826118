<template>
    <div class="container">
        <div class="wrapper">
            <div class="outer">
                <div class="input_wrapper">
                    <Field
                        v-model.trim="card.cardNumber"
                        :label-class="'label'"
                        clearable
                        maxlength="30"
                        input-align="right"
                        label="卡号"
                        placeholder="请输入卡号" />
                </div>
                <div class="input_wrapper">
                    <Field
                        v-model.trim="card.cardSecret"
                        :label-class="'label'"
                        clearable
                        maxlength="30"
                        input-align="right"
                        label="兑换码"
                        placeholder="请输入兑换码" />
                </div>
            </div>
            <div class="exchange_notes">
                查看<span
                    @click="toExchangeNotes"
                    class="link">《兑换须知》</span>
            </div>
        </div>
        <BtnBottom
            @clickEvent="handleExchange"
            :disabled="!card.cardNumber || !card.cardSecret"
            title="兑换" />

        <Dialog
            v-model="showDialog"
            :confirm-button-color="'#F76D32'"
            :cancel-button-color="'#62636C'"
            :showCancelButton="cardStatus === 1 || cardStatus === 4"
            :confirmButtonText="cardStatus === 4 ? '查看' : '确认'"
            @confirm="handleConfirm"
            @cancel="handleCancel"
            width="280px"
            show-cancel-button>
            <div class="content">
                {{ tip }}
            </div>
        </Dialog>

        <ActionSheet
            v-model="actionSheet"
            :closeable="false"
            title="兑换须知">
            <div class="exchange_notes_txt">
                <div class="van-hairline--bottom" />
                <div class="content">
                    <p>本卡仅限于思派大药房指定的线上商城使用；</p>
                    <p>本卡使用按面值金额等值使用，不设现金找零；</p>
                    <p>本卡商品抵用范围仅限指定产品（产品目录会依业务调整）；</p>
                    <p>本卡不记名、不挂失、有效期内可多次使用；</p>
                    <p>购药指导热线：4000013666-3</p>
                    <p>官方解释权归思派健康</p>
                </div>
                <BtnBottom
                    @clickEvent="closeExchangeNotes"
                    title="知道了" />
            </div>
        </ActionSheet>
    </div>
</template>

<script>
import { Field, Dialog, ActionSheet } from 'vant';
import BtnBottom from '@/components/btn-bottom';
import apis from '@/api';

export default {
    components: {
        Field,
        Dialog: Dialog.Component,
        ActionSheet,
        BtnBottom,
    },
    data() {
        return {
            card: {},
            tip: '确认激活？',
            showDialog: false,
            cardStatus: null,
            exchangeCardId: null,
            actionSheet: false,
        };
    },
    async created() {
        // 会员卡列表接口，此处调用无实际使用，只是验证token是否有效
        apis.getMCList();
    },
    methods: {
        // 兑换须知（弹窗）
        toExchangeNotes() {
            this.actionSheet = true;
        },
        closeExchangeNotes() {
            this.actionSheet = false;
        },
        async handleConfirm() {
            if (this.cardStatus === 1) {
                // 获取微信昵称、手机号、头像
                const wxInfo = await apis.getWXUserInfo();
                const { nickName, photoUrl: headImgUrl } = wxInfo?.data || {};
                const param = {
                    nickName,
                    headImgUrl,
                    phone: localStorage.getItem('phone'),
                    ...this.card,
                };
                const res = await apis.exchangeMC(param);
                if (res) {
                    this.$router.push({
                        name: 'mc_detail',
                        query: {
                            cardNo: this.card.cardNumber,
                        },
                    });
                }
            }
            if ([2, 3, 5].includes(this.cardStatus)) {
                this.showDialog = false;
            }
            if (this.cardStatus === 4) {
                this.$router.push({
                    name: 'mc_detail',
                    query: {
                        cardNo: this.card.cardNumber,
                    },
                });
            }
        },
        handleCancel() {
            this.showDialog = false;
        },
        async handleExchange() {
            const { cardNumber, cardSecret } = this.card;
            const res = await apis.checkMC({
                cardNumber,
                cardSecret,
            });
            if (res && res.data) {
                const { status, msg, exchangeCardId } = res.data;
                // 状态 1.验证成功 2.验证失败，参数不全 3.验证失败，卡号或兑换码有误 4.验证失败，被自己兑换 5.验证失败，被他人兑换
                const mapStatus = [
                    '',
                    '确认激活？',
                    msg,
                    '请填写正确的卡号与兑换码',
                    '已被您兑换',
                    '已被他人兑换',
                ];
                this.cardStatus = status;
                if (status === 4) {
                    this.exchangeCardId = exchangeCardId;
                }
                this.tip = mapStatus[status];
                this.showDialog = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    height: 100vh;
    background: #fff;
    overflow: hidden;

    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .outer {
        padding: 42px 18px 0;

        .input_wrapper {
            margin-bottom: 8px;
            padding: 0 18px;
            background: #f5f7fa;
            border-radius: 4px;

            &:last-child {
                margin-bottom: 0;
            }

            /deep/ .label {
                font-size: 16px;
                font-family: $font-family-PF-M;
                font-weight: 500;
                color: #333;
                line-height: 24px;
            }

            /deep/ .van-cell {
                padding: 13px 0;
                background: #f5f7fa;

                &::after {
                    border: none;
                }
            }
        }
    }

    .exchange_notes {
        padding-bottom: 106px;
        text-align: center;
        font-size: 14px;
        font-family: $font-family-PF-R;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);

        .link {
            color: #448bfc;
        }
    }

    /deep/ .van-dialog {
        border-radius: 4px;
    }

    /deep/ .van-button__text {
        font-size: 18px;
    }

    .content {
        padding: 24px 16px;
        font-size: 18px;
        font-family: $font-family-PF-R;
        font-weight: 400;
        color: #000;
        line-height: 25px;
        background: #fff;
        text-align: center;
    }
}

.exchange_notes_txt {
    padding-bottom: 60px;

    .content {
        min-height: 270px;

        p {
            position: relative;
            margin-bottom: 8px;
            padding-left: 12px;
            font-size: 14px;
            font-family: $font-family-PF-R;
            font-weight: 400;
            color: #333;
            line-height: 20px;
            text-align: left;

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                position: absolute;
                top: 6px;
                left: 0;
                // margin-right: 6px;
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background: #f36218;
            }
        }
    }
}
</style>
