<template>
    <div>
        <div class="banner">
            <div class="btn_text pr18">
                <span
                    @click="toMCList"
                    class="mr10">查看列表</span><img
                        :src="require('@img/membership/arrow_r.png')"
                        class="icon_arrow">
            </div>
        </div>
        <div class="pl18 pr18">
            <div class="detail mb16 pl16 pr16">
                <div class="row">
                    <div class="title color1">
                        <i class="iconfont iconicon_kamingcheng" />
                        <span class="text ml10">卡名称</span>
                    </div>
                    <div class="right color1">
                        {{ detail.name }}
                    </div>
                </div>
                <div class="row">
                    <div class="title color2">
                        <i class="iconfont iconicon_kahao" />
                        <span class="title ml10">卡号</span>
                    </div>
                    <div class="color2">
                        {{ detail.cardNumber }}
                    </div>
                </div>
                <div class="row">
                    <div class="title color3">
                        <i class="iconfont iconicon_youxiaoqi" />
                        <span class="title ml10">有效期</span>
                    </div>
                    <div class="color1">
                        <span v-if="detail.endTime">至</span>
                        {{ detail.endTime | date }}
                    </div>
                </div>
                <div class="row">
                    <div class="title color4">
                        <i class="iconfont iconicon_yue" />
                        <span class="title ml10">余额</span>
                        <span class="property ml8 pl8 pr8">
                            共{{ detail.amount | money }}元
                        </span>
                    </div>
                    <div class="color6">
                        <span class="color5">{{ detail.balance | money }}</span>
                        <span class="ml8">元</span>
                    </div>
                </div>
                <div class="row">
                    <div class="title">
                        <i class="iconfont iconicon_zhangdan" />
                        <span class="title ml10">账单</span>
                    </div>
                    <div
                        @click="toBillList"
                        class="btn_text">
                        <i class="iconfont iconicon_chakanxiangqing" />
                        <span class="ml6">查看详情</span>
                    </div>
                </div>
            </div>
            <div class="capsule">
                <img
                    @click="toApplyStoreList"
                    :src="require('@img/membership/capsule.png')"
                    alt="">
            </div>
        </div>
    </div>
</template>

<script>
import apis from '@/api';

export default {
    data() {
        return {
            detail: {},
        };
    },
    created() {
        this.getMCDetail();
    },
    methods: {
        toMCList() {
            this.$router.push({
                name: 'mc_list',
            });
        },
        toBillList() {
            this.$router.push({
                name: 'mc_bill_list',
                query: {
                    cardNo: this.detail.cardNumber,
                },
            });
        },
        toApplyStoreList() {
            this.$router.push({
                name: 'mc_store',
                query: {
                    cardId: this.detail.id,
                },
            });
        },
        async getMCDetail() {
            const { cardNo: cardNumber } = this.$route.query;
            const res = await apis.getMCDetail({ cardNumber });
            if (res) {
                const { data } = res;
                this.detail = { ...data };
            }
        },
    },
};
</script>

<style lang='scss' scoped>
.banner {
    width: 100%;
    height: 150px;
    background: url('~@img/membership/banner.png') no-repeat;
    background-size: contain;
    overflow: hidden;

    .btn_text {
        margin-top: 30px;
        color: #fff;
        text-align: right;
        font-size: 14px;
        font-family: $font-family-PF-R;
        font-weight: 400;
        line-height: 24px;

        img {
            vertical-align: middle;
        }
    }
}
.detail {
    margin-top: -70px;
    padding: 21px 16px 21px 20px;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(60, 107, 240, 0.1);
    border-radius: 8px;

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 9px;
        padding: 12px 16px 12px 12px;
        font-size: 16px;
        font-weight: 400;
        background: #f5f7fa;
        border-radius: 4px;

        &:last-child {
            margin-bottom: 0;
        }

        .title {
            display: flex;
            align-items: center;
            font-weight: 500;

            .iconfont {
                color: #3a3a3a;
                font-size: 20px;
            }

            .property {
                padding: 0 6px;
                height: 20px;
                line-height: 20px;
                font-size: 10px;
                color: #6e3a00;
                background: linear-gradient(126deg, #fddba1 0%, #ffe8bc 36%, #e7b67c 100%);
                border-radius: 4px;
            }
        }

        .right {
            max-width: 162px;
        }

        .color1 {
            color: #3a3a3a;
        }
        .color2 {
            color: #424242;
        }
        .color3 {
            color: #4c4c4c;
        }
        .color4 {
            color: #565656;
        }
        .color5 {
            color: #f36218;
        }
        .color6 {
            color: #606060;
        }

        .btn_text {
            font-size: 14px;
            font-family: $font-family-PF-M;
            font-weight: 500;
            color: #3c6bf0;
            // line-height: 24px;
        }
    }
}
.capsule {
    width: 100%;
    height: 88px;

    img {
        width: 100%;
    }
}
</style>
