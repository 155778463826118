<template>
    <div class="container pl18 pr18">
        <div class="store van-hairline--bottom">
            <div class="store_img mb14">
                <img
                    :src="detail.pictureUrl || require('@img/store.png')"
                    alt="">
            </div>
            <div
                v-ellipsis="2"
                class="store_name">
                {{ detail.fullName }}
            </div>
            <div class="profit">
                <span class="symbol">{{ detail.verifyType === 1 ? '-' : '+' }}</span>
                <span>{{ detail.verifyAmount | money }}</span>
            </div>
        </div>
        <div class="detail">
            <!-- <div v-for="item in detail.group" :key="item.key">
                <span class="label">{{ item.label }}</span><span class="value">{{ item.value }}</span>
            </div> -->
            <div class="row">
                <span class="label">交易类型：</span><span class="value">{{ detail.mapVerifyType }}</span>
            </div>
            <div class="row">
                <span class="label">时间：</span><span class="value">{{ detail.updatedTime }}</span>
            </div>
            <div class="row">
                <span class="label">余额：</span><span class="value">¥{{ detail.balance | money }}</span>
            </div>
            <div class="row">
                <span class="label">本金：</span>
                <span class="value">{{ (detail.verifyType === 1 ? '-' : '+') }}</span>
                <span>¥{{ detail.verifyAmount | money }}</span>
            </div>
            <div class="row">
                <span class="label">订单编号：</span><span class="value">{{ detail.orderNo || '--' }}</span>
            </div>
            <div class="row">
                <span class="label">卡号：</span><span class="value">{{ detail.cardNumber }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import apis from '@/api';
import { mapVerifyType } from './mapping';

export default {
    data() {
        return {
            detail: {},
        };
    },
    created() {
        this.getBillDetail();
    },
    methods: {
        async getBillDetail() {
            const { recordId } = this.$route.query;
            const res = await apis.getMCBillDetail({ recordId });
            if (res) {
                const { data: detail } = res;
                detail.mapVerifyType = mapVerifyType[detail.verifyType];
                this.detail = detail;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    height: 100vh;
    background: #fff;

    .store {
        padding: 36px 0 34px 0;

        .store_img,
        .store_name,
        .profit {
            text-align: center;
        }

        .store_img {
            img {
                width: 76px;
                height: 76px;
                border-radius: 50%;
            }
        }

        .store_name {
            margin: 0 auto 8px;
            max-width: 221px;
            font-size: 16px;
            font-family: $font-family-PF-R;
            font-weight: 400;
            color: #000;
            line-height: 24px;
        }

        .profit {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 36px;
            font-family: $font-family-DINA-B;
            font-weight: bold;
            color: #000;

            .symbol {
                font-size: 26px;
                font-family: $font-family-DINA-B;
                font-weight: bold;
                color: #000;
                line-height: 24px;
            }
        }
    }

    .detail {
        margin-top: 34px;
        font-size: 14px;
        font-weight: 400;

        .row {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            line-height: 20px;

            .label {
                padding-left: 20px;
                min-width: 90px;
                font-family: $font-family-PF-R;
                color: #9a9ea8;
            }

            .value {
                font-family: $font-family-PF-R;
                color: #3e404d;
            }
        }
    }
}
</style>
