<template>
    <div>
        <div class="pa12">
            <div
                v-for="item in list"
                :key="item.pharmacyId"
                @click="toStoreDetail(item)"
                class="store">
                <div class="left">
                    <img
                        :src="item.pictureUrl ? item.pictureUrl : require('@img/store.png')"
                        class="img">
                </div>
                <div class="info">
                    <p
                        v-ellipsis="1"
                        class="title">
                        {{ item.fullName }}
                    </p>
                    <div class="location">
                        <p
                            v-ellipsis="2"
                            class="address">
                            {{ item.provinceStr + item.cityStr + item.districtStr + item.address }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!list.length">
            <EmptyData tip="暂无数据" />
        </div>
    </div>
</template>

<script>
import EmptyData from '@/components/empty-data';
import apis from '@/api';

export default {
    components: {
        EmptyData,
    },
    data() {
        return {
            list: [],
            productCodes: [],
        };
    },
    created() {
        this.getMCApplyStore();
    },
    methods: {
        async getMCApplyStore() {
            /**
             * 扫码跳转，获取参数为模板ID（tid）
             * 业务内部跳转，传递参数为卡ID（cardId）
             */
            const { cardId, tid: templateId } = this.$route.query;
            const param = {
                cardId,
                templateId,
            };
            const res = await apis.getMCApplyStore(param);
            if (res) {
                const { pharmacyList = [], productCodes } = res.data.pharmacyList ? res.data : {};
                this.list = [...pharmacyList];
                this.productCodes = productCodes;
            }
        },
        toStoreDetail({ groupCode: merchantCode }) {
            this.$router.push({
                name: 'store',
                params: {
                    merchantCode,
                },
                query: {
                    productCodes: this.productCodes?.join(','),
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
}

.store {
    display: flex;
    margin-bottom: 8px;
    padding: 14px 18px;
    background: #fff;
    border-radius: 4px;

    &:last-child {
        margin-bottom: 0;
    }

    .img {
        width: 65px;
        height: 65px;
        border-radius: 4px;
    }

    .info {
        padding-left: 8px;

        .title {
            margin-bottom: 4px;
            font-size: 16px;
            font-family: $font-family-PF-S;
            font-weight: 600;
            color: #333;
            line-height: 22px;
        }

        .location {
            display: flex;

            .address {
                flex: 1;
                font-size: 12px;
                font-family: $font-family-PF-R;
                font-weight: 400;
                color: #333;
                line-height: 17px;
            }

            &::before {
                content: '';
                margin-right: 4px;
                width: 16px;
                height: 16px;
                background: url('~@img/icon_location.png') no-repeat;
                background-size: cover;
            }
        }
    }
}
</style>
