/* eslint-disable import/no-unresolved */
export const mapVerifyType = ['', '消费', '激活', '退款'];

export const mapAccountIcon = [
    '',
    require('@img/membership/consume.png'),
    require('@img/membership/activate.png'),
    require('@img/membership/refund.png'),
];

// export const mapCardStatus = ['', 'normal', 'invalid', 'stop'];
// 会员卡状态： 1有效 2已失效-手动 3已失效-自动 4已停用
export const mapCardStatus = ['', 'normal', 'invalid', 'invalid', 'stop'];
