<template>
    <div class="container pl18 pr18 pt15">
        <div>
            <div class="top_line mb15">
                <div>
                    <span class="title mr10">我的会员卡</span>
                    <span class="num">共{{ cardList.length }}张</span>
                </div>
                <div class="property pa8">
                    共{{ property | money }}元
                </div>
            </div>
            <div
                v-for="(item) in cardList"
                :key="item.id">
                <CouponItem
                    @toggleCouponItemSelect="toMCList"
                    :couponData="item" />
            </div>
        </div>
        <BtnBottom
            :fixed="true"
            :title="'绑定新卡'"
            @clickEvent="bindNewCard" />
    </div>
</template>

<script>
import CouponItem from '@/components/coupon-item';
import BtnBottom from '@/components/btn-bottom';
import apis from '@/api';
import { mapCardStatus } from './mapping';

export default {
    components: {
        CouponItem,
        BtnBottom,
    },
    data() {
        return {
            cardList: [],
            property: 0,
        };
    },
    created() {
        this.getMCList();
    },
    methods: {
        toMCList(card) {
            this.$router.push({
                name: 'mc_detail',
                query: {
                    cardNo: card.cardNumber,
                },
            });
        },
        bindNewCard() {
            this.$router.push({
                name: 'mc_exchange',
            });
        },
        getMCList() {
            apis.getMCList().then((res) => {
                const { data: list = [] } = res;
                this.cardList = list.map((item) => ({
                    ...item,
                    status: mapCardStatus[item.status],
                }));
                this.property = list.reduce((pre, next) => (pre + next.balance), 0);
            });
        },
    },
};
</script>

<style lang='scss' scoped>
.container {
    padding-bottom: 60px;
}
.top_line {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .title {
        font-size: 22px;
        font-family: $font-family-PF-S;
        font-weight: 600;
        color: #333;
        line-height: 24px;
    }

    .num {
        font-size: 14px;
        font-family: $font-family-PF-R;
        font-weight: 400;
        color: #62636c;
        line-height: 24px;
        letter-spacing: 2px;
    }

    .property {
        padding: 0 8px;
        height: 20px;
        line-height: 20px;
        color: #7b4200;
        background: linear-gradient(126deg, #fddba1 0%, #ffe8bc 36%, #e7b67c 100%);
        border-radius: 4px;
    }
}
</style>
