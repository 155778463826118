<template>
    <div class="container">
        <div class="title pl16">
            交易记录
        </div>
        <div
            v-if="list.length"
            class="scroll_wrapper">
            <List
                v-model="loading"
                :finished="finished"
                @load="onLoad"
                finished-text="到底啦～">
                <div class="pl16 pr18">
                    <div
                        v-for="item in list"
                        :key="item.id"
                        @click="toBillDetail(item)"
                        class="ele van-hairline--bottom">
                        <div class="left">
                            <div>
                                <img
                                    :src="item.imgUrl"
                                    class="sign"
                                    alt="">
                            </div>
                            <div class="limit_width">
                                <div class="text mb4">
                                    <span>{{ item.mapType }}</span>
                                    <span v-if="item.verifyType === 1 || item.verifyType === 3">-{{ item.tradingObject }}</span>
                                </div>
                                <div class="time">
                                    {{ item.updatedTime }}
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <span class="symbol">{{ item.verifyType === 1 ? '-' : '+' }}</span>
                            <span class="money">{{ item.verifyAmount | money }}</span>
                        </div>
                    </div>
                </div>
            </List>
        </div>
        <div
            v-else
            class="scroll_wrapper">
            <EmptyData
                :type="0"
                tip="暂无交易记录" />
        </div>
    </div>
</template>

<script>
import { List } from 'vant';
import EmptyData from '@/components/empty-data';
import apis from '@/api';
import { mapVerifyType, mapAccountIcon } from './mapping';

export default {
    components: {
        List,
        EmptyData,
    },
    data() {
        return {
            loading: false,
            finished: false,
            list: [],
            pageNumber: 1,
            pageSize: 10,
            totalPage: 0,
        };
    },
    created() {
        this.getBillList();
    },
    methods: {
        toBillDetail({ id: recordId }) {
            this.$router.push({
                name: 'mc_bill_detail',
                query: {
                    recordId,
                },
            });
        },
        onLoad() {
            if (this.totalPage && this.pageNumber < this.totalPage) {
                this.pageNumber++;
                this.getBillList();
            }
        },
        async getBillList() {
            const params = {
                cardNumber: this.$route.query.cardNo,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
            };
            const res = await apis.getMCBillList(params);
            if (res) {
                const { list, totalPage } = res.data;
                const newList = list.map((item) => ({
                    ...item,
                    imgUrl: mapAccountIcon[item.verifyType],
                    mapType: mapVerifyType[item.verifyType],
                }));
                this.list = this.list.concat(newList);
                this.totalPage = totalPage;
                this.loading = false;
                if (this.pageNumber >= this.totalPage) {
                    this.finished = true;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .van-list__finished-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    width: auto;
    font-size: 11px;
    font-family: $font-family-PF-R;
    font-weight: 400;
    color: #babfc9;
    line-height: 16px;

    &::before,
    &::after {
        content: '';
        display: inline-block;
        margin: 0 8px;
        width: 40px;
        height: 1px;
        background: #dcdfe4;
        color: red;
    }
}
.container {
    display: flex;
    flex-direction: column;

    .title {
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        font-family: $font-family-PF-R;
        font-weight: 400;
        color: #000;
    }
    .scroll_wrapper {
        height: calc(100vh - 40px);
        background: #fff;

        .ele {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // min-height: 62px;
            padding: 12px 0;

            .left {
                display: flex;
                justify-items: center;

                .sign {
                    margin-right: 9px;
                    width: 36px;
                    height: 36px;
                }

                .text {
                    font-size: 14px;
                    font-family: $font-family-PF-M;
                    font-weight: 500;
                    color: #000;
                    line-height: 22px;
                }

                .time {
                    font-size: 12px;
                    font-family: $font-family-PF-R;
                    font-weight: 400;
                    color: #6d6e78;
                    // line-height: 12px;
                }

                .limit_width {
                    max-width: 182px;
                }
            }

            .right {
                display: flex;
                align-items: center;
                font-size: 22px;
                font-family: $font-family-DINA-B;
                font-weight: bold;
                color: #333;

                .symbol {
                    font-size: 16px;
                    font-family: $font-family-DINA-B;
                    font-weight: bold;
                    color: #3a3a3a;
                    line-height: 24px;
                }
            }
        }
    }
}
/deep/ .empty_text {
    margin-top: -20px;
}
</style>
